<template>
  <section>
    <breadcrumb v-if="post" :title="$t('agents')" :subtitle="$t('new_agent')"/>
    <breadcrumb v-else :title="$t('agents')" :subtitle="$t('edit_agent')"/>
    <div class="is-flex is-flex-direction-column form-agent">
      <div class="is-flex ">
        <span @click="isData=true" class="tag is-data mx-10 rounded-10 cursor-pointer">{{ $t('data') }}</span>
        <span @click="isData=false" class="tag is-credentials mx-10 rounded-10 cursor-pointer">{{
            $t('credentials')
          }}</span>
      </div>
      <template v-if="isData">
        <div class="columns mt-20">
          <div class="column is-half">
            <b-field :label="`${$t('name')}*`" :custom-class="{'has-text-danger': error.name}">
              <b-input v-model="agent.name" type="text" placeholder="" custom-class="full-input"></b-input>
            </b-field>
            <b-field :label="`${$t('phone')}*`" :custom-class="{'has-text-danger': error.phoneNumber}">
              <b-input v-model="agent.phoneNumber" type="text" placeholder="" custom-class="full-input"></b-input>
            </b-field>
            <b-field v-if="user.userType === userTypes.MANAGER && agenciesList.data.length" :label="`${$t('agency')}*`"
                     :custom-class="{'has-text-danger': error.agencyId}">
              <multiselect
                  v-model="agency"
                  :custom-label="selectName"
                  open-direction="bottom"
                  :show-no-results="false"
                  :options="agenciesList.data">
              </multiselect>
            </b-field>
            <b-field v-if="false" :label="`${$t('exchange_rate')}*`"
                     :custom-class="{'has-text-danger': error.exchangeRate}">
              <b-input v-model="agent.exchangeRate" type="number" placeholder="" custom-class="full-input"></b-input>
            </b-field>
            <div class="mt-30">
              <b-field>
                <b-switch :value="false"
                          v-model="agent.isActive"
                          :left-label="true"
                          size="is-medium"
                          type="is-green-success">
                  {{ $t('active') }}
                </b-switch>
              </b-field>
            </div>
          </div>
          <div class="column is-half">
            <b-field :label="`${$t('email')}*`" :custom-class="{'has-text-danger': error.email}">
              <b-input v-model="agent.email" type="email" placeholder="" custom-class="full-input"></b-input>
            </b-field>
            <b-field :label="`${$t('agent_type')}`">
              <multiselect
                  v-model="agentType"
                  :custom-label="setNameAgentType"
                  open-direction="bottom"
                  :show-no-results="false"
                  :options="agentTypeList">
              </multiselect>
            </b-field>
            <b-field v-if="agentType.value !== AGENT_TYPE.INTERNAL" :label="`${$t('currency')}*`"
                     :custom-class="{'has-text-danger': error.paymentGroups}">
              <multiselect
                  track-by="id"
                  v-model="paymentGroupsValue"
                  :max="3"
                  :multiple="true"
                  :custom-label="currencyFromTo"
                  open-direction="bottom"
                  :show-no-results="false"
                  :options="paymentsGroupsList">
              </multiselect>
            </b-field>


          </div>
        </div>
        <section v-if="paymentsGroupsSelected.length">
          <h3 class="label-utility">{{ $t('utility_agents') }}</h3>
          <div class="columns">
            <div class="column is-full">
              <b-tabs type="is-boxed">
                <b-tab-item v-for="(item, idx) in paymentsGroupsSelected" :key="idx">
                  <template #header>
                    <span class="tag is-green-success mx-10 rounded-10">{{ item.currencyTo }}</span>
                  </template>
                  <div class="columns">
                    <div class="column is-half">
                      <b-field :label="`${$t('percentage_agent')}*`">
                        <b-input v-model="percentage[item.currencyTo]"
                                 type="number" custom-class="full-input"></b-input>
                      </b-field>
                    </div>
                    <div class="column is-half">
                      <b-field :label="`${$t('utility_agent')}`">
                        <b-input v-model="utilities[item.currencyTo]"
                                 type="number" custom-class="full-input"></b-input>
                      </b-field>
                    </div>
                  </div>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </section>
        <div class="columns" v-if="agent.agentType === AGENT_TYPE.EXTERNAL">
          <div class="column is-half">
            <b-field :label="`${$t('address_number', { number: 1 })}*`"
                     :custom-class="{'has-text-danger': error.addressLine}">
              <b-input v-model="agent.addressLine" type="text" placeholder="" custom-class="full-input"></b-input>
            </b-field>
            <b-field :label="$t('address_number', { number: 2 })">
              <b-input v-model="agent.addressLine2" type="text" placeholder="" custom-class="full-input"></b-input>
            </b-field>
            <b-field :label="$t('address_number', { number: 3 })">
              <b-input v-model="agent.addressLine3" type="text" placeholder="" custom-class="full-input"></b-input>
            </b-field>

          </div>
          <div class="column is-half">
            <div class="columns">
              <div class="column is-half">
                <b-field :label="`${$t('country')}*`" :custom-class="{'has-text-danger': error.country}">
                  <multiselect
                      v-model="country"
                      :custom-label="selectName"
                      open-direction="bottom"
                      :show-no-results="false"
                      :options="countryList">
                  </multiselect>
                </b-field>
                <b-field :label="`${$t('city')}*`" :custom-class="{'has-text-danger': error.municipalityId}">
                  <b-input v-model="agent.municipalityId" type="text" placeholder="" custom-class="full-input"></b-input>

<!--                  <multiselect-->
<!--                      v-model="municipality"-->
<!--                      :custom-label="selectName"-->
<!--                      open-direction="bottom"-->
<!--                      :show-no-results="false"-->
<!--                      :options="cityList">-->
<!--                  </multiselect>-->
                </b-field>

              </div>
              <div class="column is-half">
                <b-field :label="`${$t('state')}*`" :custom-class="{'has-text-danger': error.provinceId}">
                  <multiselect
                      v-model="province"
                      :custom-label="stateName"
                      open-direction="bottom"
                      :show-no-results="false"
                      :options="stateList">
                  </multiselect>
                </b-field>
                <b-field :label="`${$t('zip')}*`" :custom-class="{'has-text-danger': error.zipCode}">
                  <b-input v-model="agent.zipCode" type="text" placeholder="" custom-class="full-input"></b-input>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="columns mt-20">
          <div class="columns is-align-items-center is-flex">
            <div class="column is-4 is-align-items-center is-flex-direction-column">
              <div v-if="!avatarPreview" @click="toggleShow" class="rounded-50x avatar cursor-pointer">
                <span class="mdi mdi-account-circle account-avatar"></span>
              </div>
              <div @click="toggleShow" v-else class="cursor-pointer">
                <img class="avatar-load" :src="avatarPreview" alt="">
              </div>
              <b-field hidden>
                <b-upload ref="pic" drag-drop native v-model="avatar">
                  <span class="file-cta">
                      <b-icon class="file-icon" icon="upload"></b-icon>
                      <span class="file-label">{{ $t('scan_firm') }}</span>
                  </span>
                </b-upload>
              </b-field>
            </div>
            <div class="column is-4 is-flex is-flex-direction-column">
              <span class="help">{{ $t('avatar') }}</span>
              <span class="help">{{ $t('avatar_help') }}</span>
            </div>
          </div>
          <div class="column is-half">
            <b-field :label="`${$t('username')}*`" :custom-class="{'has-text-danger': error.email}">
              <b-input v-model="agent.email" type="text" placeholder="" custom-class="full-input"></b-input>
            </b-field>
            <b-field :label="`${$t('password')}*`" :custom-class="{'has-text-danger': error.password}">
              <b-input v-model="agent.password"
                       type="password"
                       required
                       :validation-message="$t('password_required')"
                       password-reveal
                       icon-clickable custom-class="full-input"></b-input>
            </b-field>
            <section v-if="pass_val && agent.password" class="is-flex is-flex-direction-column">
              <password-required/>
            </section>
          </div>
        </div>
      </template>

      <div class="is-flex is-justify-content-flex-end">
        <router-link :to="{name:'listAgent'}">
          <b-button class="btn btn-back mx-10">
            {{ $t('back') }}
          </b-button>
        </router-link>

        <b-button v-if="!pass_val" @click="save" class="btn btn-continue mx-10" type="is-primary">
          {{ $t('save') }}
        </b-button>
        <b-button v-else disabled class="btn btn-continue mx-10" type="is-primary">
          {{ $t('save') }}
        </b-button>
      </div>

    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import Multiselect from "vue-multiselect";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {AGENT_TYPE} from "@/enum/agentType";
import {notification} from "@/utils";
import {USER_TYPE} from "@/enum/userType";
import {TYPE_ERROR} from "@/enum/typeError";
import PasswordRequired from "@/components/modals/password-required";

export default {
  name: "NewAgentComponent",
  components: {PasswordRequired, Breadcrumb, Multiselect},
  data() {
    return {
      pass_val: false,
      isData: true,
      show: false,
      cityList: [],
      stateList: [],
      country: null,
      province: null,
      municipality: null,
      agency: null,
      agentType: null,
      paymentGroups: [],
      paymentGroupsValue: [],
      paymentsGroupsList: [],
      paymentsGroupsSelected: [],
      avatar: null,
      avatarPreview: null,
      file: null,
      post: true,
      userTypes: USER_TYPE,
      AGENT_TYPE,
      utilities: {},
      percentage: {},
      error: {
        email: false,
        password: false,
        username: false,
        name: false,
        agencyId: false,
        phoneNumber: false,
        addressLine: false,
        provinceId: false,
        municipalityId: false,
        country: false,
        paymentGroups: false,
        zipCode: false,
      },
      agent: {
        email: null,
        exchangeRate: null,
        password: null,
        isActive: false,
        name: null,
        identificationNumber: null,
        phoneNumber: null,
        profilePicture: null,
        agencyId: null,
        userType: null,
        agentType: AGENT_TYPE.INTERNAL,
        userTypeName: null,
        addressLine: null,
        addressLine2: null,
        addressLine3: null,
        provinceId: null,
        municipalityId: null,
        country: null,
        paymentGroups: [],
        zipCode: null,
        id: null
      },
    }
  },
  computed: {
    ...mapGetters({
      countryList: 'GET_COUNTRY_LIST',
      agentTypeList: 'GET_AGENT_TYPE',
      agenciesList: 'GET_AGENCY_LIST',
      lang: 'GET_LANG',
      user: 'GET_USER'
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'SET_LOADING'
    }),
    ...mapActions({
      fetchCountry: 'FETCH_COUNTRY',
      fetchState: 'FETCH_STATES_BY_COUNTRY',
      fetchCity: 'FETCH_CITY',
      fetchPaymentsGroups: 'FETCH_PAYMENTS_GROUPS',
      saveAgent: 'SAVE_AGENT',
      editAgent: 'EDIT_AGENT',
      fetchAgency: 'FETCH_AGENCY',
      fetchAgent: 'FETCH_AGENT',
      uploadPicture: 'UPLOAD_FILE',
      goTo: 'GO_TO'
    }),
    save() {
      this.agent.exchangeRate = Number(this.agent.exchangeRate)
      this.agent.paymentGroups = []
      this.paymentsGroupsSelected.forEach(value => {
        this.agent.paymentGroups.push({
          id: value.id,
          agencyToAgentRate: Number(this.percentage[value.currencyTo]),
          agencyToAgentUtilityValue: Number(this.utilities[value.currencyTo])
        })
      })
      if (this.validate()) {
        if (this.post) {
          if (this.avatar) {
            this.uploadPicture({file: this.avatar})
                .then(picture => {
                  this.agent.profilePicture = picture
                  this.saveAgent(this.agent).then(() => {
                    this.goTo('listAgent')
                  })
                })
          } else {
            this.saveAgent(this.agent).then(() => {
              this.goTo('listAgent')
            })
          }
        } else {
          if (this.avatar) {
            this.uploadPicture({file: this.avatar})
                .then(picture => {
                  this.agent.profilePicture = picture
                  this.editAgent(this.agent).then(() => {
                    this.goTo('listAgent')
                  })
                })
          } else {
            this.editAgent(this.agent).then(() => {
              this.goTo('listAgent')
            })
          }
        }

      }

    },
    toggleShow() {
      this.$refs.pic.$el.click()
    },
    getImgUrl(file) {
      return new Promise(resolve => {
        if (file !== undefined) {
          const fr = new FileReader();
          fr.readAsDataURL(file);
          fr.addEventListener('load', () => {
            resolve(fr.result)
          })
        }
      })

    },
    validate() {
      let has_error = false;
      if (this.post) {
        if (this.agent.password === null) {
          this.error.password = true
          has_error = true
        }
        if (this.agent.agencyId === null) {
          this.error.agencyId = true
          has_error = true
        }
        if (this.agent.username === null) {
          this.error.username = true
          has_error = true
        }
        if (this.agent.name === null) {
          this.error.name = true
          has_error = true
        }
        if (this.agent.phoneNumber === null) {
          this.error.phoneNumber = true
          has_error = true
        }
        if (this.agent.addressLine === null) {
          this.error.addressLine = true
          has_error = true
        }
        if (this.agent.country === null) {
          this.error.country = true
          has_error = true
        }
        if (this.agent.email === null) {
          this.error.email = true
          has_error = true
        }
        if (this.agent.municipalityId === null) {
          this.error.municipalityId = true
          has_error = true
        }
        if (this.agent.paymentGroups.length === 0 && this.agentType.value !== AGENT_TYPE.INTERNAL) {
          this.error.paymentGroups = true
          has_error = true
        }
        if (this.agent.provinceId === null) {
          this.error.provinceId = true
          has_error = true
        }
        if (this.agent.zipCode === null) {
          this.error.zipCode = true
          has_error = true
        }
        if (has_error) {
          notification('Llene todos los campos obligatorios', TYPE_ERROR.WARNING)
          return false

        }
      }
      return true
    },
    currencyFromTo({currencyTo}) {
      return currencyTo
    },
    selectName({name}) {
      return name
    },
    setNameAgentType({value}) {
      switch (value) {
        case AGENT_TYPE.EXTERNAL:
          return this.lang === 'es' ? 'External' : 'Externo';
        case AGENT_TYPE.INTERNAL:
          return this.lang === 'es' ? 'Internal' : 'Interno';

      }
    },
    stateName({name}) {
      return name
    },
    getPaymentsGroup(current = 1, record = 10, type = 1) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: null,
        type: type
      };
      return new Promise(resolve => {
        this.fetchPaymentsGroups(data).then(value => resolve(value))
      })

    },
    getAgencies(current = 1, record = 0, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchAgency(data).then(value => {
          resolve(value)
        })
      })
    },
    getAgents(current = 1, record = 0, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchAgent(data)
            .then(value => {
              resolve(value)
            })
      })

    },
  },
  watch: {
    'agent.password': function () {
      if (this.post){
        let regex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].)(?=.*[a-z]).{8,}$/;
        this.pass_val = !regex.test(this.agent.password);
      } else if (!this.post && !this.agent.password) {
        this.pass_val = false
      }
    },
    avatar: async function () {
      this.avatarPreview = await this.getImgUrl(this.avatar)
    },
    'agency': async function () {
      this.agent.agencyId = this.agency.id
      this.paymentsGroupsList = this.agency.paymentGroups
      if (this.agent.agentType === AGENT_TYPE.INTERNAL) {
        const agency = await this.fetchAgency({id:this.agency.id})
        this.agent.country = agency.country;
        this.agent.municipalityId = agency.municipality;
        this.agent.provinceId = agency.province;
        this.agent.zipCode = agency.zipCode;
        this.agent.addressLine = agency.addressLine;
        this.agent.addressLine2 = agency.addressLine;
        this.agent.addressLine3 = agency.addressLine;
      }

    },
    'agentType': async function () {
      this.agent.agentType = this.agentType.value
      if (this.agentType.value === AGENT_TYPE.INTERNAL) {
        this.paymentsGroupsSelected = []
        if (this.agency) {
          const agency = await this.fetchAgency({id:this.agency.id})
          this.agent.country = agency.country;
          this.agent.municipalityId = agency.municipality;
          this.agent.provinceId = agency.province;
          this.agent.zipCode = agency.zipCode;
          this.agent.addressLine = agency.addressLine;
          this.agent.addressLine2 = agency.addressLine;
          this.agent.addressLine3 = agency.addressLine;
        }
      }else {
        this.agent.country = null;
        this.agent.municipalityId = null;
        this.agent.provinceId = null;
        this.agent.zipCode = null;
        this.agent.addressLine = null;
        this.agent.addressLine2 = null;
        this.agent.addressLine3 = null;
      }
    },
    'country': async function () {
      this.agent.country = this.country.name
      this.stateList = await this.fetchState(this.country.id)
    },
    'province': async function () {
      this.agent.provinceId = this.province.name
      this.cityList = await this.fetchCity(this.province.id)
    },
    'municipality': function () {
      this.agent.municipalityId = this.municipality.name
    },
    'paymentGroupsValue': function () {
      let temp = [];
      let all = []
      this.paymentGroupsValue.forEach(item => {
        temp.push(item.id)
        all.push(item)
      })
      this.paymentGroups = temp
      this.paymentsGroupsSelected = all
    },
  },
  mounted() {
    if (this.post) {
      this.agentType = this.agentTypeList[0]
    }
  },
  created() {
    this.setLoading()
    this.getPaymentsGroup()
    if (this.$route.params.agencyId || this.user.userType === USER_TYPE.AGENCY) {
      let id = this.$route.params.agencyId ? this.$route.params.agencyId : this.user.id
      this.getAgencies(1, 1, id)
          .then(value => {
            this.agency = value
          })
    } else {
      this.getAgencies()
    }
    this.fetchCountry()
        .then(() => {
          if (this.$route.params.id) {
            this.post = false
            this.getAgents(1, 1, this.$route.params.id)
                .then(value => {
                  this.agent = value
                  this.agent.addressLine = value.addressLine
                  this.agentType = this.agentTypeList.find(at => at.value === value.agentType)
                  if (value.agentType === AGENT_TYPE.EXTERNAL){
                    value.paymentGroups.forEach(item => {
                      this.paymentGroupsValue.push(item)
                      this.percentage[item.currencyTo] = item.agentCommission.rate
                      this.utilities[item.currencyTo] = item.agentCommission.utilityValue
                    })
                  }
                  this.avatarPreview = value.profilePicture
                  this.agent.zipCode = value.zipCode
                  this.getAgencies(1, 1, value.agencyId)
                      .then(value => {
                        this.agency = value
                      })
                  this.country = this.countryList.find(c => c.name === value.country)
                  this.fetchState(this.country.id)
                      .then(states => {
                        this.province = states.find(s => s.name === value.province)
                        this.agent.municipalityId = value.municipality
                        // this.fetchCity(this.province.id)
                        //     .then(cities => {
                        //       this.agent.municipalityId = cities.find(c => c.name === value.municipality)
                        //       this.agent.municipalityId = this.municipality
                        //     })
                      })
                })

          }
        }).finally(() => {
      this.setLoading(false)
    })

  }

}
</script>

<style scoped lang="scss">
.label-utility {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;
}

.form-agent {
  //padding-left: 138px;

  .avatar-load {
    border-radius: 50%;
    min-height: 200px;
  }

  .tag {
    font-weight: bold;
    font-size: 12px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.5px;
    width: 111px;

    &.is-credentials {
      color: #28BCE8;
      border: 1px solid #28BCE8;

    }

    &.is-data {
      color: #FFFFFF;
      background: #28BCE8;
    }


  }
}

.btn {
  width: 316px;
  height: 58px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;

  &.btn-back {
    border: 1px solid #28BCE8;
    color: #28BCE8;
  }

  &.btn-continue {
    background: #28BCE8;
    color: #FFFFFF;
  }
}


.help {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #C4C4C4;
}

.avatar {
  height: 156px;
  width: 156px;
  background: #DEDDDD;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-right: 0 !important;
}

.account-avatar {
  font-size: 100px;
}
</style>