<template>
<div class="is-flex is-flex-direction-column">
  <span class="danger font-size-20 color-red">{{ $t('password_requiments') }}</span>
  <span class="color-red">{{ $t('pass_min_chart') }}</span>
  <span class="color-red">{{ $t('pass_max_chart') }} </span>
  <span class="color-red">{{ $t('pass_val_capital_letter') }}</span>
  <span class="color-red">{{ $t('pass_val_lower_letter') }}</span>
  <span class="color-red">{{ $t('pass_val_number') }}</span>
  <span class="color-red">{{ $t('pass_val_blank_letter') }}</span>
  <span class="color-red">{{ $t('pass_val_character') }}</span>
</div>
</template>

<script>
export default {
name: "password-required"
}
</script>

<style scoped>

</style>